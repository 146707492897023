import { useLocation, useHistory } from 'react-router-dom'
import { useMemo, useCallback } from 'react'
import { base64ToBytes, bytesToBase64 } from 'byte-base64'
import msgpack5 from 'msgpack5'
import short from 'short-uuid'

const msgpack = msgpack5()
const shortTranslator = short(short.constants.cookieBase90)

const ENC = {
  '+': '-',
  '/': '_',
  '=': '.'
}
const DEC = {
  '-': '+',
  _: '/',
  '.': '='
}

const encoder = (m) => ENC[m]
const decoder = (m) => DEC[m]

const safeEncode = (base64) => {
  return base64.replace(/[+/=]/g, encoder)
}

const safeDecode = (safe) => {
  return safe.replace(/[-_.]/g, decoder)
}

export function parseSession(str) {
  return msgpack.decode(base64ToBytes(safeDecode(str)))
}

export function serializeSession(data) {
  // The big benchmark
  // console.log('msgpack', msgpack.encode(data).length)
  // console.log('JSON', JSON.stringify(data).length)
  // console.log('urlenc(msgpack)', encodeURIComponent(msgpack.encode(data)).length)
  // console.log('urlenc(JSON)', encodeURIComponent(JSON.stringify(data)).length)
  // console.log('urlenc(urlenc(JSON))', encodeURIComponent(encodeURIComponent(JSON.stringify(data))).length)
  // console.log('lzw(msgpack)', lzw.compress(msgpack.encode(data)).length)
  // console.log('lzw(JSON)', lzw.compress(JSON.stringify(data)).length)
  // console.log('b64(lzw(msgpack))', bytesToBase64(lzw.compress(msgpack.encode(data))).length)
  // console.log('b64(lzw(JSON))', bytesToBase64(lzw.compress(JSON.stringify(data))).length)
  // console.log('b64(msgpack)', bytesToBase64(msgpack.encode(data)).length)
  // console.log('b64(JSON)', bytesToBase64(JSON.stringify(data)).length)
  return safeEncode(bytesToBase64(msgpack.encode(data)))
}

export function useSession() {
  const location = useLocation()
  return useMemo(() => {
    const query = new URLSearchParams(location.search)
    const data = query.get('d')
    if (!data) { return null }
    return parseSession(data)
  }, [location])
}

export function useSetSession() {
  const location = useLocation()
  const history = useHistory()
  return useCallback((session, push) => {
    const query = new URLSearchParams(location.search)
    query.set('d', serializeSession(session))
    const func = push ? history.push : history.replace
    func({ ...location, search: '?' + query.toString() })
  }, [location, history])
}

export const newSession = (session) => {
  return { id: shortTranslator.new(), ...session }
}
