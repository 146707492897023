import React, { useState, useRef, useLayoutEffect, useCallback, useEffect } from 'react'
import styled, { useTheme } from 'styled-components'
import useResize from 'use-resize'
import { CardContainer, Card } from './Card'
import { CardTooltip } from './CardTooltip'
import { useLocation, Redirect } from 'react-router-dom'
import useDimensions from 'react-use-dimensions'
import { motion } from 'framer-motion'
import connectionWords from '../constants/words'
import { Modal } from './Modal'
import { WordsModal } from './WordsModal'
import { Exiting, useExiting } from './ExitAnimation'

const wordModalTimeout = 30 * 1000

const ScrollContainer = styled.div`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
`

const Container = styled.div`
  position: relative;
  width: ${props => props.width}px;
  height: 100%;
  user-select: none;
`

const WordContainer = styled(motion.div)`
  position: absolute;
  left: -56px;
  top: -16px;
  width: 112px;
  height: 32px;
  background-color: white;
  border-radius: 8px;
  ${props => props.theme.shadow};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: ${props => props.onClick ? 'pointer' : 'inherit'};
  user-select: none;
  -webkit-tap-highlight-color: transparent;
`

const WordLabel = styled.div`
  color: ${props => (props.full || props.clickable) ? props.theme.colors.text : props.theme.colors.textDisabled};
  font-weight: ${props => props.full ? 'bold' : 'inherit'};
`

const Word = ({ index, wordId, x, y, onClick }) => {
  const transform = {
    x, y,
    scale: 1,
    opacity: 1,
  }
  return (
    <Exiting>
      <WordContainer
        initial={{
          x, y,
          scale: 0.5,
          opacity: 0,
        }}
        animate={transform}
        whileHover={onClick == null ? transform : {
          x, y,
          scale: 1.1,
          opacity: 1,
        }}
        whileTap={onClick == null ? transform : {
          x, y,
          scale: 0.95,
          opacity: 1,
        }}
        exit={{
          x, y,
          scale: 0.5,
          opacity: 0,
        }}
        transition={{
          duration: 0.3,
          ease: 'easeInOut',
        }}
        onClick={onClick}
      >
        <WordLabel full={wordId != null} clickable={!!onClick}>
          {wordId != null ? connectionWords[wordId] : (
            onClick ? 'Make connection' : 'No connection'
          )}
        </WordLabel>
      </WordContainer>
    </Exiting>
  )
}

const cardSpacing = 32

export function ListenCards({ playlist, playlistCursor, maxPlaylistCursor, words, onChangeWord, shared }) {
  const { width: windowWidth, height: windowHeight } = useResize()
  const theme = useTheme()
  const scrollRef = useRef(null)

  const [tooltip, setTooltip] = useState({
    cardIndex: null,
    transform: null,
  })

  const [wordModal, setWordModal] = useState({
    open: false,
    index: 0,
  })

  const location = useLocation()
  const fromPickInitial = !!(location.state && location.state.fromPick)
  const [fromPick] = useState(fromPickInitial)

  const viewportWidth = windowWidth
  const scrollContainerHeight = windowHeight - theme.bottomDrawerHeight - theme.headerHeight

  const [containerRef, containerDimensions] = useDimensions()
  const viewportHeight = containerDimensions.height ?? scrollContainerHeight

  const tooltipClearance = theme.tooltipClearance + 8
  const cardHeight = theme.card.height
  const cardWidth = theme.card.width
  const invCardHeight = 1.0 / cardHeight
  const selectedCardScale = Math.min(2.0,
    0.9 * (viewportHeight - tooltipClearance) * invCardHeight
  )
  const selectedCardHeight = selectedCardScale * cardHeight
  const selectedCardWidth = selectedCardScale * cardWidth
  const cardY = viewportHeight * 0.5 - Math.max(0,
    tooltipClearance - (viewportHeight - selectedCardHeight) * 0.5
  )
  const cardScale = Math.min(0.8 * selectedCardScale, 0.6 * viewportHeight)

  const containerPadding = viewportWidth >= 2.0 * selectedCardWidth
    ? selectedCardWidth * 0.5 + cardSpacing
    : viewportWidth * 0.5
  let x = containerPadding

  let selectedTransform

  const cards = []
  const wordsElems = []

  playlist.forEach((cardIndex, index) => {
    const isSelected = index === playlistCursor
    if (index > 0) {
      x += cardSpacing + theme.card.width * (
        (index - 1 === playlistCursor || isSelected)
          ? (selectedCardScale * 0.5 + cardScale * 0.5)
          : cardScale
      )
    }

    const scale = isSelected ? selectedCardScale : cardScale
    const transform = { x, y: cardY, scale }

    if (index > 0 && index <= maxPlaylistCursor) {
      wordsElems.push(
        <Word
          key={index}
          index={index - 1}
          wordId={words[index - 1]}
          x={x - (scale * cardWidth + cardSpacing) * 0.5}
          y={cardY}
          onClick={shared ? undefined : () => {
            setWordModal({ open: true, index: index - 1 })
          }}
        />
      )
    }

    if (isSelected) {
      selectedTransform = transform
    }

    const onHoverStart = () => {
      setTooltip({ cardIndex, transform })
    }
    const onHoverEnd = () => {
      setTooltip({ cardIndex: null, transform: null })
    }

    cards.push(
      <CardContainer
        key={cardIndex}
        initial={false}
        layoutId={`card-${cardIndex}`}
        animate={transform}
        transition={{
          duration: 0.3,
          ease: 'easeInOut',
        }}
        onHoverStart={onHoverStart}
        onHoverEnd={onHoverEnd}
        onTapStart={onHoverStart}
        onTap={onHoverEnd}
        onTapCancel={onHoverEnd}
      >
        <Exiting>
          <Card
            initial={fromPick ? false : { opacity: 0 }}
            animate={{
              opacity: 1,
            }}
            transition={{
              duration: 0.3,
              ease: 'easeInOut',
            }}
            exit={{ opacity: 0 }}
            cardIndex={cardIndex}
          />
        </Exiting>
      </CardContainer>
    )
  });

  x += containerPadding

  useLayoutEffect(() => {
    if (!scrollRef.current) { return }

    const scrollOffset = Math.max(0, Math.min(x - viewportWidth,
      -0.5 * viewportWidth + selectedTransform.x
    ))

    scrollRef.current.scrollTo({
      left: scrollOffset,
      behavior: 'smooth',
    })
  }, [selectedTransform.x, viewportWidth, x])

  const onWordClose = useCallback(() => {
    setWordModal(state => ({ ...state, open: false }))
  }, [])

  const onWordSelect = useCallback((wordId) => {
    onChangeWord(wordModal.index, wordId)
    setWordModal(state => ({ ...state, open: false }))
  }, [wordModal, onChangeWord])

  // After some time, ask the player to select word
  useEffect(() => {
    if (!shared && playlistCursor > 0 && playlistCursor === maxPlaylistCursor && words[playlistCursor - 1] == null) {
      const timerId = setTimeout(() => {
        setWordModal({ open: true, index: playlistCursor - 1 })
      }, wordModalTimeout)
      return () => { clearTimeout(timerId) }
    }
  }, [playlistCursor, maxPlaylistCursor, words, shared])

  const exiting = useExiting()

  return (
    <ScrollContainer
      ref={scrollRef}
      width={viewportWidth}
      height={scrollContainerHeight}
    >
      <Container
        ref={containerRef}
        width={x}
      >
        {cards}
        {wordsElems}
        <CardTooltip
          cardIndex={!exiting && tooltip.cardIndex !== playlist[playlistCursor] ? tooltip.cardIndex : null}
          transform={tooltip.transform}
          margin={8}
        />
        <CardTooltip
          cardIndex={!exiting ? playlist[playlistCursor] : null}
          transform={selectedTransform}
          margin={8}
        />
        {fromPickInitial && <Redirect to={{ ...location, state: null }} />}
      </Container>
      <Modal open={wordModal.open} onClose={onWordClose}>
        <WordsModal
          onChange={onWordSelect}
          isCurrent={wordModal.index === playlistCursor - 1}
        />
      </Modal>
    </ScrollContainer>
  )
}
