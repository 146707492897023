import styled from 'styled-components'
import { motion } from 'framer-motion'

export const BottomDrawer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: ${props => props.theme.bottomDrawerHeight}px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  user-select: none;
`

export const BottomDrawerBackground = styled(motion.div)`
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  ${props => props.theme.shadow};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
`
