const pad2Digits = s => s < 10 ? `0${s}` : s

export const formatSeconds = s => {
  const minutes = Math.floor(s / 60)
  const seconds = Math.floor(s - minutes * 60)
  if (minutes < 60) {
    return `${pad2Digits(minutes)}:${pad2Digits(seconds)}`
  }
  const hours = Math.floor(minutes / 60)
  const mins = Math.floor(minutes - hours * 60)
  return `${hours}:${pad2Digits(mins)}:${pad2Digits(seconds)}`
}

export const cardImageURL = cardIndex => `${process.env.PUBLIC_URL}/cards/card${(cardIndex ?? -1) + 1}.jpg`
export const trackURL = cardIndex => `${process.env.PUBLIC_URL}/audio/track${(cardIndex ?? -1) + 1}.mp3`
