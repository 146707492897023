import React, { useCallback } from 'react'
import { Exiting, useNavigateExiting } from './ExitAnimation'
import { ArticleWrapper } from './ArticleWrapper'
import { useSession, serializeSession } from '../session'
import { Redirect } from 'react-router-dom'
import { Quote } from './Quote'
import { StartListeningButton, getPlaylistDuration } from './StartListeningButton'
import styled from 'styled-components'
import { PlaylistShowcase } from './PlaylistShowcase'

const StartButton = styled(StartListeningButton)`
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em;
  display: block;
`

export const SharedWelcomePage = () => {
  const session = useSession()

  const navigateExiting = useNavigateExiting()
  const onStartListening = useCallback(() => {
    navigateExiting(history => {
      history.push('/shared/listen?d=' + serializeSession(session))
    })
  }, [navigateExiting, session])

  if (!session || !session.playlist) {
    return <Redirect to='/' />
  }

  return (
    <Exiting>
      <ArticleWrapper
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <h1>{session.name ?? "Someone"} shared a playlist with you</h1>
        <p>Welcome to <strong>Experiments in Living</strong>, an online card-based experience created to enhance the listening of our album by creating playlists and connecting the tracks with concepts that you get to choose.</p>
        <p>They were dealt this playlist and chose the words that connect the cards. Listen and see if you can hear what they heard, and then try your own!</p>
        <PlaylistShowcase playlist={session.playlist} />
        {!!session.name && !!session.quote && (
          <Quote author={session.name}>{session.quote}</Quote>
        )}
        <StartButton duration={getPlaylistDuration(session.playlist)} onClick={onStartListening}>Listen</StartButton>
      </ArticleWrapper>
    </Exiting>
  )
}
