import React, { createContext, useContext, useEffect, useCallback, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'

export const ExitingContext = createContext(false)
export const NavigateExitingContext = createContext(null)
export const useExiting = () => useContext(ExitingContext)
export const useNavigateExiting = () => useContext(NavigateExitingContext)

export const useNavigateExitingTo = (location) => {
  const navigateExiting = useNavigateExiting()
  return useCallback((evt) => {
    if (evt) { evt.preventDefault() }
    navigateExiting(history => { history.push(location) })
  }, [location, navigateExiting])
}

export const ExitingProvider = ({ children }) => {
  const [exiting, setExiting] = useState(null)

  const navigateExiting = useCallback((cb, duration = 0.5) => {
    setExiting({ cb, duration })
  }, [])

  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    if (exiting) {
      let timerId
      timerId = setTimeout(() => {
        timerId = null
        exiting.cb(history, location)
        setExiting(null)
      }, exiting.duration * 1000)
      return () => {
        if (timerId) { clearTimeout(timerId) }
      }
    }
  }, [exiting, history, location])

  return <ExitingContext.Provider value={!!exiting}>
    <NavigateExitingContext.Provider value={navigateExiting}>
      {children}
    </NavigateExitingContext.Provider>
  </ExitingContext.Provider>
}

export const Exiting = ({ children }) => {
  const exiting = useExiting()
  return (
    <AnimatePresence>
      {exiting ? null : children}
    </AnimatePresence>
  )
}
