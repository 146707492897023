import React from 'react'
import { Exiting, useNavigateExitingTo } from './ExitAnimation'
import { ArticleWrapper } from './ArticleWrapper'
import { useSession } from '../session'
import { Redirect } from 'react-router-dom'
import { Quote } from './Quote'
import { BuyCTA } from './BuyCTA'
import { CenteredButton } from './Button'

export const SharedEndPage = () => {
  const session = useSession()
  const goHome = useNavigateExitingTo('/')

  if (!session) {
    return <Redirect to='/' />
  }

  return (
    <Exiting>
      <ArticleWrapper
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <h1>You listened to {session.name || 'someone else'}'s playlist</h1>
        {!!session.name && !!session.quote && (
          <Quote author={session.name}>{session.quote}</Quote>
        )}
        <CenteredButton onClick={goHome}>Make your own playlist</CenteredButton>
        <BuyCTA />
      </ArticleWrapper>
    </Exiting>
  )
}
