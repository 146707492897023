import React, { useCallback } from 'react'
import { CenteredButton } from './Button'
import { Exiting, useNavigateExiting, useNavigateExitingTo } from './ExitAnimation'
import { ArticleWrapper } from './ArticleWrapper'
import styled from 'styled-components'

const BottomPolicy = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 8px;
`

export const WelcomePage = () => {
  const navigateExiting = useNavigateExiting()

  const onStartClick = useCallback(() => {
    navigateExiting(history => {
      history.push('/start')
    })
  }, [navigateExiting])

  const onPrivacyPolicyClick = useNavigateExitingTo('/privacy-policy')

  return (
    <Exiting>
      <ArticleWrapper
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <h1>Welcome</h1>
        <p>Welcome to <strong>Experiments in Living</strong>, a digital art project created to enhance your listening of our album. Inside you will see large “Collage” cards, each of which corresponds to a track from the album. You can choose your playlist order or can have one dealt to you at random.</p>
        <p><strong>Listen closely to the tracks of your playlist</strong> – as the 2nd selection plays, you’ll be presented with a list of descriptive words that serve as connections between tracks. What to choose? That is up to you, for there is no “correct” answer when it comes to interpreting this music.</p>
        <p>Each playlist you create becomes an <strong>experiment in living</strong> through the music – devising your own stories and logic about how and why this music thrives together.</p>
        <CenteredButton onClick={onStartClick}>
          Start Your Experiment
        </CenteredButton>
        <BottomPolicy>
          <a href='/privacy-policy' onClick={onPrivacyPolicyClick}>We don't track you</a>
        </BottomPolicy>
      </ArticleWrapper>
    </Exiting>
  )
}
