export default [
  "Liquid",
  "Ecstatic",
  "Melancholy",
  "Ephemeral",
  "Enigmatic",
  "Euphoria",
  "Extemporaneous",
  "Giggles",
  "Clustered",
  "Skittering",
  "Tactile",
  "Mercurial",
  "Lucid",
  "Immersive",
  "Tangled",
  "Fractured",
  "Gutsy",
  "Intimate",
  "Unspoken",
  "Skyward",
  "Aching",
  "Iridescent",
  "Undulating",
  "Hazy",
  "Fertile",
  "Glitchy",
  "Hungry",
  "Morphing",
  "Earthy",
  "Vast",
  "Bananas",
  "Sublime",
  "Yikes",
  "Nostalgia",
  "Uncertain",
  "Aerial",
  "Exuberance",
  "Architectural",
  "Transportive",
  "Flammable",
  "Precocious",
  "Unpredictable",
  "Contrast",
  "Immortal",
  "Repetition",
  "Timeless",
  "Prismatic",
  "Flamboyant",
  "Conversation",
  "Oblique",
  "Luxurious",
  "Deviant",
  "Magnetic",
  "Frisky",
  "Alluring",
  "Motley",
  "Labyrinthine",
  "Relentless",
  "Fearless",
  "Microscopic",
  "Tranquil",
  "Sillypants",
  "Gentle",
  "Childlike",
]
