import React, { useState, useCallback } from 'react'
import tracks from '../constants/tracks'
import { BottomDrawer, BottomDrawerBackground } from './BottomDrawer'
import styled, { useTheme }  from 'styled-components'
import { motion } from 'framer-motion'
import { StartListeningButton, getPlaylistDuration } from './StartListeningButton'
import { CardMatrix } from './CardMatrix'
import { serializeSession, newSession } from '../session'
import { useNavigateExiting, Exiting } from './ExitAnimation'

const allCards = []
for (let i = 0; i < tracks.length; i++) {
  allCards.push(i)
}

const DrawCTAContainer = styled.div`
  flex: 1;
  align-self: stretch;
  position: relative;
`

const DrawCTA = styled(motion.div)`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  color: ${props => props.theme.colors.text};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 18px;
`

const ArrowIcon = styled.div`
  margin-right: 8px;
  margin-left: 16px;
`

const Instructions = styled.div`
  text-align: right;
`

const Header = styled(motion.h1)`
  width: 100%;
`

export const PickPage = () => {
  const [playlist, setPlaylist] = useState([])
  const playlistNotEmpty = playlist.length > 0

  const playlistDuration = getPlaylistDuration(playlist)

  const navigateExiting = useNavigateExiting()
  const onStartListeningClick = useCallback(() => {
    navigateExiting(history => {
      const session = newSession({ playlist })
      history.push('/listen?d=' + serializeSession(session), {
        fromPick: true,
      })
    })
  }, [playlist, navigateExiting])

  const theme = useTheme()

  const onToggleCard = useCallback((card) => {
    setPlaylist(playlist => {
      const index = playlist.indexOf(card)
      if (index === -1) {
        return [...playlist, card]
      }
      const newPlaylist = [...playlist]
      newPlaylist.splice(index, 1)
      return newPlaylist
    })
  }, [])

  return <>
    <CardMatrix
      cards={allCards}
      selectedCards={playlist}
      onToggleCard={onToggleCard}
      bottomPadding={theme.bottomDrawerHeight}
      headerHeight={84}
      header={(
        <Header
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          Pick your cards
        </Header>
      )}
    />
    <BottomDrawer>
      <Exiting>
        <BottomDrawerBackground
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        />
      </Exiting>
      <DrawCTAContainer>
        <Exiting>
          <DrawCTA
            key={`${playlistNotEmpty}`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <ArrowIcon className='fl-bigmug-line-up107' />
            {playlistNotEmpty
              ? <Instructions>Click on cards to add/remove them to your playlist, then</Instructions>
              : <Instructions>Click on cards to build your playlist</Instructions>
            }
            {playlistNotEmpty && (
              <StartListeningButton
                duration={playlistDuration}
                onClick={onStartListeningClick}
              />
            )}
          </DrawCTA>
        </Exiting>
      </DrawCTAContainer>
    </BottomDrawer>
  </>
}
