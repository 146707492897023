const bottomDrawerHeight = 88
const deckOutset = 40
const bottomClearance = bottomDrawerHeight + deckOutset
const desktopDeckOutset = 116
const desktopBottomClearance = bottomDrawerHeight + desktopDeckOutset

export const theme = {
  colors: {
    text: '#333333',
    textDisabled: '#bbbbbb',
    lightText: '#ffffff',
    darkBackground: '#181818',
    lightBackground: '#ffffff',
  },
  card: {
    width: 1122 / 8,
    height: 1797 / 8,
    borderRadius: 24 / 4
  },
  fonts: {
    body: `mr-eaves-modern, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
    stylish: 'novecento-sans-condensed, sans-serif',
  },
  shadow: 'box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px',
  bottomDrawerHeight,
  deckOutset,
  bottomClearance,
  bottomScrollClearance: bottomClearance,
  tooltipClearance: 104,
  headerHeight: 60,
  maxContentWidth: 600,
  desktop: false,
}

export const desktopTheme = {
  ...theme,
  bottomScrollClearance: desktopBottomClearance,
  bottomClearance: bottomDrawerHeight,
  deckOutset: desktopDeckOutset,
  desktop: true,
}
