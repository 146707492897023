import React, { useState, useCallback, useRef, useEffect } from 'react'
import tracks from '../constants/tracks'
import DealingDeck from './DealingDeck'
import shuffle from 'lodash.shuffle'
import { BottomDrawer, BottomDrawerBackground } from './BottomDrawer'
import useResize from 'use-resize'
import styled, { useTheme } from 'styled-components'
import { motion } from 'framer-motion'
import { StartListeningButton, getPlaylistDuration } from './StartListeningButton'
import { CardMatrix } from './CardMatrix'
import SweetScroll from 'sweet-scroll'
import { CardTooltip } from './CardTooltip'
import { serializeSession, newSession } from '../session'
import { useNavigateExiting, Exiting } from './ExitAnimation'

const defaultDeck = []
for (let i = 0; i < tracks.length; i++) {
  defaultDeck.push(i)
}

const deckMargin = 16
const deckWidthFraction = 0.3
const maxDeckWidth = 200

const DrawCTAContainer = styled.div`
  flex: 1;
  align-self: stretch;
  position: relative;
`

const DrawCTA = styled(motion.div)`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  color: ${props => props.theme.colors.text};
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
`

const ArrowIcon = styled.div`
  margin-right: 8px;
`

const TooltipContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
`

export const DealPage = () => {
  const [state, setState] = useState(() => ({
    deck: shuffle(defaultDeck),
    playlist: [],
    lastCard: null,
    dealing: false,
    tooltipTransform: null,
    tooltipCardIndex: null,
  }))

  const { deck, playlist } = state;
  const playlistNotEmpty = playlist.length > 0
  const deckNotEmpty = deck.length > 0

  const matrixRef = useRef(null)

  const onDealBegin = useCallback((cardTransform) => {
    setState(state => ({
      ...state,
      dealing: true,
      lastCard: state.deck[0],
      lastCardInitialTransform: null,
      tooltipTransform: cardTransform,
      tooltipCardIndex: state.deck[0],
    }))
  }, [])

  const scroller = useRef(null)

  const onDealEnd = useCallback((cardTransform) => {
    scroller.current && scroller.current.destroy()
    scroller.current = new SweetScroll()

    const bottom = document.body.clientHeight - window.innerHeight
    scroller.current.to(bottom, {
      duration: 300,
      easing: 'easeInOutQuad',
      complete: () => {
        const { x, y } = matrixRef.current.getBoundingClientRect()
        setState(state => ({
          ...state,
          deck: state.deck.slice(1),
          playlist: [...state.playlist, state.deck[0]],
          dealing: false,
          lastCard: state.deck[0],
          lastCardInitialTransform: {
            x: cardTransform.x - x,
            y: cardTransform.y - y,
            scale: cardTransform.scale,
          },
          tooltipTransform: null,
          tooltipCardIndex: null,
        }))

        scroller.current && scroller.current.destroy()
        scroller.current = null
      }
    })
  }, [])

  useEffect(() => () => {
    scroller.current && scroller.current.destroy()
  }, [])

  const { width: windowWidth } = useResize()
  const theme = useTheme()

  const deckWidth = Math.min(
    (windowWidth - 2 * deckMargin) * deckWidthFraction,
    maxDeckWidth
  )
  const deckScale = deckWidth / theme.card.width
  const deckHeight = theme.card.height * deckScale
  const deckLeft = deckMargin + 0.5 * deckWidth
  const deckTop = 0.5 * deckHeight - theme.deckOutset

  const playlistDuration = getPlaylistDuration(playlist)

  const navigateExiting = useNavigateExiting()
  const onStartListeningClick = useCallback(() => {
    navigateExiting(history => {
      const session = newSession({ playlist })
      history.push('/listen?d=' + serializeSession(session), {
        fromPick: true,
      })
    })
  }, [playlist, navigateExiting])

  // Because createGlobalStyle doesn't unmount
  useEffect(() => {
    window.document.body.style.overflowX = 'hidden'
    return () => {
      window.document.body.style.overflowX = null
    }
  }, [])

  return <>
    <CardMatrix
      ref={matrixRef}
      cards={playlist}
      lastCard={state.lastCard}
      lastCardInitialTransform={state.lastCardInitialTransform}
      bottomPadding={theme.bottomScrollClearance}
    />
    <BottomDrawer>
      <Exiting>
        <BottomDrawerBackground
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        />
      </Exiting>
      <DrawCTAContainer>
        <Exiting>
          <DrawCTA
            key={`${playlistNotEmpty}-${deckNotEmpty}`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div
              style={{
                width: deckNotEmpty
                  ? deckWidth + 2 * deckMargin
                  : deckMargin
              }}
            />
            {deckNotEmpty &&
              <ArrowIcon className='fl-bigmug-line-left148' />
            }
            {deckNotEmpty && (playlistNotEmpty
              ? <div>Draw or</div>
              : <div>Draw cards to build your playlist</div>
            )}
            {playlistNotEmpty && (
              <StartListeningButton
                duration={playlistDuration}
                onClick={onStartListeningClick}
              />
            )}
          </DrawCTA>
        </Exiting>
      </DrawCTAContainer>

      <Exiting>
        <DealingDeck
          key={windowWidth} // The position is messed when dragging
          topCard={deck[0]}
          cardsLeft={Math.max(deck.length - 1, 0)}
          dealing={state.dealing}
          onDealBegin={onDealBegin}
          onDealEnd={onDealEnd}
          left={deckLeft}
          top={deckTop}
          scale={deckScale}
        />
      </Exiting>

      <TooltipContainer>
        <CardTooltip
          transform={state.tooltipTransform}
          cardIndex={state.tooltipCardIndex}
        />
      </TooltipContainer>
    </BottomDrawer>
  </>
}
