import React, { useState, useCallback }  from 'react'
import { Exiting } from './ExitAnimation'
import { ArticleWrapper } from './ArticleWrapper'
import { useSession, serializeSession } from '../session'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import twitterLogo from './images/twitter_logo.svg'
import facebookLogo from './images/facebook_logo.svg'
import { Input } from './Input'
import { BuyCTA } from './BuyCTA'
import { facebookAppId } from '../constants/tokens'
import { PlaylistShowcase } from './PlaylistShowcase'

const socialPrefilledText = 'Check out this playlist from Spektral Quartet\'s latest album: Experiments in Living'

const Container = styled.div`
  position: relative;
  width: 100%;
`

const CharCount = styled.div`
  width: 100%;
  text-align: right;
  font-size: 20px;
  margin-bottom: 8px;
`

const Wrapper = styled(motion.div)`
  display: flex;
  min-height: 100%;
`

const SocialButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  img {
    height: 60px;
    width: 60px;
  }

  a:not(:first-child) {
    margin-left: 16px;
  }
`

const LinkInput = styled(Input)`
  width: 100%;
`

const LinkCopy = ({ value }) => {
  const [copied, setCopied] = useState(false)

  const onFocus = useCallback((evt) => {
    evt.target.select()
    evt.target.setSelectionRange(0, value.length)
    try {
      document.execCommand("copy")
      evt.preventDefault()
      setCopied(true)
    } catch (ex) {
      console.error(ex)
    }
  }, [value])

  const onBlur = useCallback(() => {
    setCopied(false)
  }, [])

  return (<>
    <LinkInput
      type='text'
      value={value}
      readOnly
      onFocus={onFocus}
      onBlur={onBlur}
    />
    {<CharCount style={{ marginBottom: 0 }}>
      {copied ? 'Copied to clipboard!' : <span>&nbsp;</span>}
    </CharCount>}
  </>)
}

const SectionTitle = styled.h3`
  text-align: center;
`

const Instructions = styled.p`
  text-align: center;
`

const ShareUI = ({ session, onEdit }) => {
  const serializedSession = serializeSession(session)

  const url = new URL(window.location)
  url.hash = ''
  url.search = '?d=' + serializedSession
  url.pathname = '/shared'
  const shareLink = url.href

  if (url.hostname === 'localhost') { // So that we can test Facebook share on localhost
    url.host = 'example.com'
  }

  url.searchParams.set('utm_source', 'facebook')
  const facebookShareLink = `https://www.facebook.com/dialog/share?app_id=${facebookAppId}&display=popup&href=${encodeURIComponent(url.href)}&quote=${encodeURIComponent(socialPrefilledText)}`

  const onFacebookShareClick = useCallback((evt) => {
    evt.preventDefault()
    const width = 680
    const left = ((window?.screen?.width || window.innerWidth) - width) * 0.5
    window.open(facebookShareLink, 'eil_facebook', `menubar=no,toolbar=no,width=${width},height=500,left=${left},top=100`)
  }, [facebookShareLink])

  url.searchParams.set('utm_source', 'twitter')
  const twitterShareLink = `https://twitter.com/intent/tweet?text=${encodeURIComponent(socialPrefilledText)}&url=${encodeURIComponent(url.href)}`

  return (
    <Wrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <ArticleWrapper>
        <h1>Share this playlist</h1>
        <Instructions>Share your playlist and your friends will hear what you heard and see the connections you made.</Instructions>
        <PlaylistShowcase playlist={session.playlist} />
        <SectionTitle>Share on social media:</SectionTitle>
        <SocialButtons>
          <a href={facebookShareLink} target='_blank' rel='noopener noreferrer' onClick={onFacebookShareClick}>
            <img src={facebookLogo} alt='Share on Facebook' />
          </a>
          <a href={twitterShareLink} target='_blank' rel='noopener noreferrer'>
            <img src={twitterLogo} alt='Share on Twitter' />
          </a>
        </SocialButtons>
        <SectionTitle>Or copy the link:</SectionTitle>
        <LinkCopy value={shareLink} />
        <BuyCTA />
      </ArticleWrapper>
    </Wrapper>
  )
}

export const SharePage = () => {
  const session = useSession()

  return (
    <Container>
      <Exiting>
          <ShareUI session={session} />
      </Exiting>
    </Container>
  )
}
