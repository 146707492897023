import React, { forwardRef, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import useResize from 'use-resize'
import { CardContainer, Card } from './Card'
import { motion, AnimatePresence } from 'framer-motion'
import { CardTooltip } from './CardTooltip'
import { useExiting } from './ExitAnimation'
import { NoBackground } from './NoBackground'

const cardMargin = 8
const desiredCardWidth = 160

const Container = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-align: stretch;
  padding-top: ${cardMargin}px;
  padding-right: ${cardMargin}px;
  padding-bottom: ${props => cardMargin + (props.bottomPadding ?? 0)}px;
  padding-left: ${cardMargin}px;
  flex-direction: row;
  flex-wrap: wrap;
  user-select: none;
`

const CardSlot = styled.div`
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  margin: ${cardMargin}px;
  width: ${props => props.scale * props.theme.card.width}px;
  height: ${props => props.scale * props.theme.card.height}px;
`

const CardNumber = styled(motion.div)`
  color: ${props => props.theme.colors.lightText};
  font-family: ${props => props.theme.fonts.stylish};
  font-size: ${props => props.theme.card.height * 0.6}px;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  line-height: 0;
  top: ${props => props.theme.card.height * 0.45}px;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.9);
`

const EmptyPlaceholder = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
  padding: 104px 16px 40px 16px;
  font-size: 24px;
  font-family: ${props => props.theme.fonts.stylish};
  text-transform: uppercase;
  color: ${props => props.theme.colors.text};
`

export const CardMatrix = forwardRef(({
  cards, lastCardInitialTransform, lastCard, bottomPadding, selectedCards, onToggleCard,
  header, headerHeight,
}, ref) => {
  const { width: windowWidth } = useResize()
  const theme = useTheme()

  const [tooltip, setTooltip] = useState({
    cardIndex: null,
    transform: null,
    placement: null,
  })

  const usableWidth = windowWidth - cardMargin * 2
  const cardsPerRow = Math.max(1, Math.round(usableWidth / (desiredCardWidth + 2 * cardMargin)))
  const cardWidth = (usableWidth / cardsPerRow) - 2 * cardMargin
  const cardScale = cardWidth / theme.card.width
  const cardHeight = cardScale * theme.card.height

  const offsetX = cardMargin * 2 + cardWidth * 0.5
  const offsetY = cardMargin * 2 + cardHeight * 0.5 + (headerHeight ?? 0)
  const strideX = cardMargin * 2 + cardWidth
  const strideY = cardMargin * 2 + cardHeight

  const addPlaceholder = lastCard != null && !cards.includes(lastCard)

  const exiting = useExiting()

  return (
    <Container ref={ref} bottomPadding={bottomPadding}>
      <NoBackground />
      <AnimatePresence>
        {!exiting && header}
      </AnimatePresence>
      <AnimatePresence>
        {(!exiting && cards.length === 0 && lastCard == null) &&
          <EmptyPlaceholder
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            Begin by drawing a few cards
          </EmptyPlaceholder>
        }
      </AnimatePresence>
      {cards.map((cardIndex, index) => {
        const row = Math.floor(index / cardsPerRow)
        const column = index - row * cardsPerRow
        const transform = {
          x: offsetX + strideX * column,
          y: offsetY + strideY * row,
          scale: cardScale,
          opacity: 1,
        }

        const onHoverStart = () => {
          const placement = row === 0 || (
            (row + 1) * cardsPerRow < cards.length + (addPlaceholder ? 1 : 0)
          )
            ? 'bottom'
            : 'top'
          setTooltip({ cardIndex, transform, placement })
        }
        const onHoverEnd = () => {
          setTooltip({ cardIndex: null, transform: null })
        }

        const selectedIndex = selectedCards?.indexOf(cardIndex)
        const shouldExitOut = (selectedCards && selectedIndex === -1)

        return (
          <CardSlot
            key={cardIndex}
            scale={cardScale}
          >
            <AnimatePresence>
              {!(exiting && shouldExitOut) && <CardContainer
                initial={(
                  cardIndex === lastCard && lastCardInitialTransform
                ) || false}
                layoutId={`card-${cardIndex}`}
                animate={transform}
                exit={shouldExitOut ? {
                  ...transform,
                  opacity: 0,
                } : undefined}
                transition={{
                  duration: 0.3,
                  ease: 'easeInOut',
                }}
                onHoverStart={onHoverStart}
                onHoverEnd={onHoverEnd}
                onTapStart={onHoverStart}
                onTap={onHoverEnd}
                onTapCancel={onHoverEnd}
                whileTap={onToggleCard ? {
                  ...transform,
                  scale: cardScale * 0.95,
                } : undefined}
                onClick={onToggleCard ? () => {
                  onToggleCard(cardIndex)
                } : undefined}
              >
                <Card
                  cardIndex={cardIndex}
                />
                <AnimatePresence>
                  {!exiting && selectedIndex != null && selectedIndex !== -1 && (
                    <CardNumber
                      key={selectedIndex}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.3 }}
                    >
                      {selectedIndex + 1}
                    </CardNumber>
                  )}
                </AnimatePresence>
              </CardContainer>}
            </AnimatePresence>
          </CardSlot>
        )
      })}
      {addPlaceholder && (
        <CardSlot
          key={lastCard}
          scale={cardScale}
        />
      )}
      <CardTooltip
        cardIndex={tooltip.cardIndex}
        transform={tooltip.transform}
        placement={tooltip.placement || 'bottom'}
        margin={8}
      />
    </Container>

  )
})
