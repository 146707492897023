import React from 'react'
import styled from 'styled-components'
import { formatSeconds } from './util'
import { Button } from './Button'
import tracks from '../constants/tracks'

const StartListeningButtonDiv = styled(Button)`
  margin-left: 8px;
  margin-right: 8px;
  line-height: 1;
  flex-shrink: 0;
`

const DurationSpan = styled.span`
  font-size: 0.8em;
`

const DurationIcon = styled.span`
  position: relative;
  font-size: 0.8em;
  margin-right: 4px;
`

export const StartListeningButton = ({ duration, onClick, className }) => (
  <StartListeningButtonDiv onClick={onClick} className={className}>
    START LISTENING
    <br />
    <DurationSpan>
      Total{' '}
      <DurationIcon className='fl-bigmug-line-nine16' />
      {formatSeconds(duration)}
    </DurationSpan>
  </StartListeningButtonDiv>
)

export const getPlaylistDuration = (playlist) => playlist.reduce(((acc, trackIndex) =>
  acc + tracks[trackIndex].duration
), 0)
