import React from 'react'
import styled from 'styled-components'
import tracks from '../constants/tracks'

const Composer = styled.div`
  margin-top: 0.5em;
`

const Movement = styled.div`
  margin-top: 0.25em;
  font-style: italic;
`

const NoBreak = styled.span`
  white-space: nowrap;
`

export const TrackMetadata = ({ cardIndex }) => {
  const meta = tracks[cardIndex]
  if (!meta) {
    return 'Unknown track'
  }
  return <>
    <b>{cardIndex + 1}.</b> {meta.title} (<i>{meta.year}</i>) {meta.featuring && `featuring ${meta.featuring}`}
    <br />
    {meta.movement && (<Movement>{meta.movement}<br /></Movement>)}
    <Composer>
      {meta.composerFirstName} {meta.composerLastName} <NoBreak>(
        <i>{!meta.composerDeathYear ? `b${meta.composerBirthYear}` : `${meta.composerBirthYear} - ${meta.composerDeathYear}`}</i>
      )</NoBreak>
    </Composer>
  </>
}
