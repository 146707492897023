import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { cardImageURL } from './util'

export const CardContainer = styled(motion.div)`
  position: absolute;
  perspective: 400px;
  top: ${props => -props.theme.card.height * 0.5}px;
  left: ${props => -props.theme.card.width * 0.5}px;
  width: ${props => props.theme.card.width}px;
  height: ${props => props.theme.card.height}px;
`

export const CardDiv = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  border-radius: ${props => props.theme.card.borderRadius}px;
  width: ${props => Math.floor(props.theme.card.width)}px;
  height: ${props => Math.floor(props.theme.card.height)}px;
  transform-origin: 50% 50%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  ${props => props.theme.shadow};
  background-color: #333;
  -webkit-touch-callout: none;
  user-select: none;

  img {
    border-radius: ${props => props.theme.card.borderRadius}px;
    width: 100%;
    height: 100%;
    -webkit-touch-callout: none;
    user-select: none;
  }
`

const preventDefault = (evt) => {
  evt.preventDefault()
}

export const Card = ({ cardIndex, ...otherProps }) => (
  <CardDiv {...otherProps}>
    <img src={cardImageURL(cardIndex)} draggable={false} alt='' onContextMenu={preventDefault} />
  </CardDiv>
)
