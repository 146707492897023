import React, { useRef, useCallback, useEffect, memo } from 'react'
import styled, { useTheme } from 'styled-components'
import { Card, CardContainer } from './Card'
import { motion } from 'framer-motion'

const dealDuration = 2 * 1000

const Deck = styled(motion.div)`
  position: absolute;
  left: ${props => props.left}px;
  top: ${props => props.top}px;
  width: 0;
  height: 0;
`

const zeroConstraints = {
  top: 0, left: 0, right: 0, bottom: 0,
}

const DealingDeck = memo((props) => {
  const {
    topCard, cardsLeft,
    dealing, onDealBegin, onDealEnd,
    left, top, scale,
  } = props

  const ref = useRef(null)
  const centerOfScreen = useRef({ x: 0, y: 0, scale: 1 })
  const timeout = useRef(null)

  const theme = useTheme()

  const onDragEnd = useCallback((evt, info) => {
    if (info && (Math.abs(info.offset.x) + Math.abs(info.offset.y)) <= 10) {
      return
    }

    const { x, y } = ref.current.getBoundingClientRect()
    const { innerWidth, innerHeight } = window
    const topPadding = theme.headerHeight
    const bottomPadding = theme.bottomClearance + theme.tooltipClearance
    const centerX = innerWidth * 0.5
    const centerY = topPadding + (innerHeight - topPadding - bottomPadding) * 0.5
    const scale = 0.9 * Math.min(
      innerWidth / theme.card.width,
      (innerHeight - topPadding - bottomPadding) / theme.card.height,
    )

    centerOfScreen.current = {
      x: centerX - x,
      y: centerY - y,
      scale,
    }

    const clientTransform = {
      x: centerX,
      y: centerY,
      scale,
    }

    if (timeout.current) { clearTimeout(timeout.current) }
    timeout.current = setTimeout(() => {
      timeout.current = null
      onDealEnd && onDealEnd(clientTransform)
    }, dealDuration)

    onDealBegin && onDealBegin(clientTransform)
  }, [theme, onDealBegin, onDealEnd])

  useEffect(() => {
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current)
        timeout.current = null
      }
    }
  }, [])

  const placeholderCards = []
  const maxCards = 3
  for (let i = 0; i < maxCards; i++) {
    const index = maxCards - i - 1
    const transform = {
      x: index * 2,
      y: index * 2,
      scale,
      opacity: cardsLeft <= index ? 0 : 1,
    }
    placeholderCards.push(
      <CardContainer
        key={`placeholder-${i}`}
        initial={false}
        animate={transform}
        style={{
          pointerEvents: 'none',
        }}
      >
        <Card/>
      </CardContainer>
    )
  }

  return (
    <Deck
      ref={ref}
      left={left}
      top={top}
      initial={{ y: theme.card.height * scale }}
      animate={{ y: 0 }}
      exit={{ y: theme.card.height * scale }}
      transition={{
        duration: 0.5,
        easing: 'easeOut',
      }}
    >
      {placeholderCards}
      {topCard !== undefined && (
        <CardContainer
          key={topCard}
          layoutId={`dealing-${topCard}`}
          drag={!dealing}
          dragConstraints={zeroConstraints}
          initial={false}
          animate={dealing
            ? centerOfScreen.current
            : { x: 0, y: 0, scale }
          }
          dragElastic={1}
          whileTap={{
            scale: dealing
              ? centerOfScreen.current.scale
              : scale * 1.1,
          }}
          onClick={onDragEnd}
          onDragEnd={onDragEnd}
        >
          <Card
            cardIndex={topCard}
            initial={false}
            transition={{
              rotateY: { duration: 0.5 }
            }}
            animate={{
              rotateY: dealing ? 0 : 180,
            }}
          />
          <Card
            initial={false}
            transition={{
              rotateY: { duration: 0.5 }
            }}
            animate={{
              rotateY: dealing ? -180 : 0,
            }}
          />
        </CardContainer>
      )}
    </Deck>
  )
})

DealingDeck.defaultProps = {
  left: 0,
  top: 0,
  scale: 1,
}

export default DealingDeck
