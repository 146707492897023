import React from 'react'
import { CenteredButton } from './Button'
import { Exiting, useNavigateExitingTo } from './ExitAnimation'
import { ArticleWrapper } from './ArticleWrapper'

const PrivacyPolicy = () => (
  <>
    <h1>Privacy Policy</h1>

    <p>eil.spektralquartet.com doesn't collect or share your personal information.</p>

    <p>We don't track you. We don't profile you. We don't store anything about you.</p>
  </>
)

export const PrivacyPolicyPage = () => {
  const onGoHome = useNavigateExitingTo('/')

  return (
    <Exiting>
      <ArticleWrapper
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <PrivacyPolicy />
        <CenteredButton onClick={onGoHome}>
          Get started
        </CenteredButton>
      </ArticleWrapper>
    </Exiting>
  )
}
