import React, { useCallback, useState } from 'react'
import styled, {useTheme} from 'styled-components'
import { Button, LightButton } from './Button'
import { useRouteMatch } from 'react-router-dom'
import { motion, AnimatePresence } from 'framer-motion'
import { Modal } from './Modal'
import { AboutModal } from './AboutModal'
import { useNavigateExitingTo } from './ExitAnimation'
import useResize from 'use-resize'

const HeaderContainer = styled.div`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  height: ${props => props.theme.headerHeight}px;
  background-color: ${props => props.theme.colors.darkBackground};
  ${props => props.theme.shadow};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  user-select: none;
`

const HeaderLogo = styled(motion.a)`
  height: 100%;
  img {
    height: 100%;
  }
`

const EmptySpace = styled.div`
  flex: 1;
`

const Title = styled.div`
  ${props => !props.theme.desktop ? 'display: none;' : ''}
  font-family: ${props => props.theme.fonts.stylish};
  font-size: 24px;
  color: ${props => props.theme.colors.lightText};
  margin-left: 16px;
  text-transform: uppercase;
  align-self: stretch;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const AboutButton = styled(Button)`
  margin-left: 8px;
  min-width: 37px;
  box-shadow: inset 0px 0px 0px 1px white;
`

const SecondaryButton = styled(LightButton)`
  overflow-x: hidden;
  white-space: nowrap;
`

const Header = () => {
  const sharedMatch = useRouteMatch('/shared')
  const rootMatch = useRouteMatch({ path: '/', exact: true })
  const startMatch = useRouteMatch({ path: '/start', exact: true })

  const [aboutOpen, setAboutOpen] = useState(false)

  const onAboutClick = useCallback(() => {
    setAboutOpen(open => !open)
  }, [])

  const onClose = useCallback(() => {
    setAboutOpen(false)
  }, [])

  const onHomeClick = useNavigateExitingTo('/')
  const onStartClick = useNavigateExitingTo('/start')
  const { width } = useResize()
  const theme = useTheme()

  return (<>
    <HeaderContainer>
      <HeaderLogo
        href='https://spektralquartet.com/experiments-in-living'
        animate={{ scale: 1 }}
        whileTap={{ scale: 0.9 }}
        whileHover={{ scale: 0.9 }}
      >
        <img src='/logo_header.svg' alt='Spektral Quartet' />
      </HeaderLogo>
      {width >= 540 && (
        <Title onClick={onHomeClick}>
          <div>Experiments in Living</div>
        </Title>
      )}
      <EmptySpace />
      <AnimatePresence>
        {!rootMatch && !startMatch && (
          <SecondaryButton
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            as={motion.div}
            onClick={sharedMatch ? onHomeClick : onStartClick}
          >
            {sharedMatch ? 'Make your own' : 'Start Over'}
          </SecondaryButton>
        )}
      </AnimatePresence>
      <AboutButton onClick={onAboutClick}>{theme.desktop ? 'About' : '?'}</AboutButton>
    </HeaderContainer>
    <Modal open={aboutOpen} onClose={onClose}>
      <AboutModal />
    </Modal>
  </>)
}

const HeaderPadding = styled.div`
  width: 100%;
  height: ${props => props.theme.headerHeight}px;
`

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: calc(100vh - ${props => props.theme.headerHeight}px);
  flex-direction: column;
  align-items: stretch;
  justify-content: begin;
`

export const HeaderWrapper = ({ children }) => (
  <>
    <Header />
    <HeaderPadding />
    <ContentWrapper>
      {children}
    </ContentWrapper>
  </>
)
