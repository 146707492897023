import React from 'react'
import { ModalTitle, ModalTextContent } from './Modal'
import styled from 'styled-components'
import words from '../constants/words'

const Container = styled.div`
  margin-top: 8px;
  margin-bottom: 16px;
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  user-select: none;
`

const WordButton = styled.button`
  padding: 8px;
  border: none;
  --webkit-touch-callout: transparent;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.2s ease;
  font-family: ${props => props.theme.fonts.body};
  font-size: 18px;
  outline: none;
  border-radius: 8px;
  width: ${props => props.theme.desktop ? 25 : 50}%;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.2);
  }
`

export const WordsModal = ({ isCurrent, onChange }) => (
  <>
    <ModalTitle>
      {isCurrent
        ? 'Which word connects this track with the previous?'
        : 'Which word connects these two tracks?'
      }
    </ModalTitle>
    <ModalTextContent>
      <Container>
        {words.map((word, wordId) => (
          <WordButton
            key={wordId}
            onClick={() => { onChange(wordId) }}
          >
            {word}
          </WordButton>
        ))}
      </Container>
    </ModalTextContent>
  </>
)
