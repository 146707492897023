import React from 'react'
import { ModalTitle, ModalTextContent } from './Modal'
import styled from 'styled-components'

const BuiltByCritique = styled.p`
  text-align: center;

  a img {
    height: 3em;
    vertical-align: middle;
  }

  a:hover {
    opacity: 0.6;
  }
`

export const AboutModal = () => (
  <>
    <ModalTitle>Experiments in Living</ModalTitle>
    <ModalTextContent>
      <p><strong>This album will never be heard the same way twice</strong>. Do you remember the <em>Choose Your Own Adventure</em> books?&nbsp;“If you approach the mysterious stranger, turn to page 48...If you duck into the forbidden cave to avoid them, turn to page 136,” etc. There was a thrill to the equal possibilities of adventure or capture, friendship or deception, renown or obscurity.</p>
      <p><strong><em>Experiments in Living</em></strong><em> </em>is the musical analog, and one where your listening experience will change profoundly based on the choices you make at each fork in the road. While routes through the record can be ever-changing, this double album flows along two currents. <strong>Part One</strong> pursues three historic innovators and singular voices circumventing the entrenched rules of their time – <strong>Johannes Brahms, Arnold Schoenberg</strong>, and <strong>Ruth Crawford (Seeger)</strong> – while <strong>Part Two</strong> pitches through passageways of music motivated by jazz, improvisation, and experimentation by way of composers <strong>Anthony Cheung, Sam Pluta, Charmaine Lee</strong>, and <strong>George Lewis</strong>. Finding the provocative connections between them is what defines our work together.</p>
      <p>Read more about the album in an <a href='https://spektralquartet.com/experiments-in-living/about' target='_blank' rel='noopener noreferrer'>extended liner note here</a>, and learn much more <a href='http://spektralquartet.com/experiments-in-living/music' target="_blank" rel="noopener noreferrer">about the music here</a>.</p>
      <h1>Choose your own path</h1>
      <p><em>Experiments in Living</em> is enhanced by Tarot-style interpretive cards with art from Copenhagen-based collage artist and musician<strong> </strong><a href="http://instagram.com/oejerum" target="_blank" rel="noopener noreferrer"><strong>øjeRum</strong></a>, to create an innovative, self-guided, “choose your own adventure” approach to experiencing the album.</p>
      <p>Each of the 20 tracks corresponds to a gorgeous "Collage" card, and descriptive "Bridge" cards are used to create conceptual links between tracks in your playlists. A shuffle of the deck reveals a unique playlist every time you listen, and invites you to uncover and reflect on new connections each time.</p>
      <p>If you like <strong>Experiments in Living</strong>, consider supporting us by{' '}
        <a href='https://spektralquartet.com/experiments-in-living' target='_blank' rel='noopener noreferrer'>
          buying the album or the physical cards
        </a>.
      </p>
      <BuiltByCritique>
        Web app built with <span role='img' aria-label='love'>🖤</span> by <a href='https://critique-gaming.com' target='_blank' rel='noopener noreferrer'>
          <img width='115' height='48' src='/critique_logo.svg' alt='Critique Gaming' />
        </a>
      </BuiltByCritique>
    </ModalTextContent>
  </>
)
