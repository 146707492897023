import React from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import styled, { useTheme } from 'styled-components'
import { TrackMetadata } from './TrackMetadata'

const Tooltip = styled(motion.div)`
  position: absolute;
  left: ${props => props.left}px;
  top: ${props => props.top}px;
  ${props => props.top != null
    ? `top: ${props.top}px`
    : `bottom: ${props.bottom}px`
  };
  width: ${props => props.width}px;
  height: 0px;
  z-index: 200;
  pointer-events: none;
  text-align: center;
`

const InnerTooltip = styled.div`
  position: absolute;
  background-color: ${props => props.theme.colors.darkBackground};
  box-sizing: border-box;
  color: ${props => props.theme.colors.lightText};
  border-radius: 8px;
  padding: 8px;
  ${props => props.anchor}: 0;
  transform: translate(-50%, 0);
  width: 100%;
  min-width: 150px;
`

export const CardTooltip = ({ transform, cardIndex, margin, placement }) => {
  const theme = useTheme()

  let width, left, top, bottom
  if (transform) {
    width = theme.card.width * transform.scale
    const height = theme.card.height * transform.scale
    left = transform.x
    if (placement === 'top') {
      top = transform.y - height * 0.5 - margin
    } else {
      top = transform.y + height * 0.5 + margin
    }
  }

  return (
    <AnimatePresence>
      {transform != null && cardIndex != null && (
        <Tooltip
          key={cardIndex}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit ={{ opacity: 0 }}
          top={top}
          bottom={bottom}
          left={left}
          width={width}
        >
          <InnerTooltip
            anchor={placement === 'top' ? 'bottom' : 'top'}
          >
            <TrackMetadata cardIndex={cardIndex} />
          </InnerTooltip>
        </Tooltip>
      )}
    </AnimatePresence>
  )
}

CardTooltip.defaultProps = {
  margin: 16,
  placement: 'bottom',
}
