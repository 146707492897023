import styled from "styled-components";

export const Button = styled.button`
  background-color: ${props => props.theme.colors.darkBackground};
  color: ${props => props.theme.colors.lightText};
  padding: 6px 8px;
  border-radius: 4px;
  font-family: ${props => props.theme.fonts.stylish};
  text-transform: uppercase;
  font-size: ${props => props.small ? 16 : 20}px;
  text-align: center;
  ${props => props.theme.shadow};
  transition: transform 0.2s ease;
  border: none;
  outline: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
  transform: scale(1);
  user-select: none;

  &:hover {
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.95);
    outline: none;
  }
`

export const LightButton = styled(Button)`
  background-color: ${props => props.theme.colors.lightBackground};
  color: ${props => props.theme.colors.text};
`

export const CenteredButton = styled(Button)`
  display: block;
  margin-left: auto;
  margin-right: auto;
`
