import React  from 'react'
import styled from 'styled-components'

const QuoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${props => props.theme.fonts.body};
  font-style: italic;
  font-size: 20px;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
`

const QuoteContent = styled.div`
`

const QuoteFooter = styled.div`
  padding-left: 16px;
  text-align: right;
`

const QuoteEdit = styled.a`
  margin-left: 8px;
  opacity: 0.5;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
    text-decoration: underline;
  }
`

const QuoteAuthor = styled.span`
`

export const Quote = ({ children, author, onEdit }) => (
  <QuoteContainer>
    <QuoteContent>
      ‟{children}”
    </QuoteContent>
    {(!!author || onEdit) && (
      <QuoteFooter>
        {!!author && (
          <QuoteAuthor>
            － {author}
          </QuoteAuthor>
        )}
        {onEdit && <QuoteEdit onClick={onEdit}>Edit quote</QuoteEdit>}
      </QuoteFooter>
    )}
  </QuoteContainer>
)
