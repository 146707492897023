import React from 'react'
import { createGlobalStyle } from 'styled-components'
import { trackURL } from './util'
import AudioPlayer from 'react-h5-audio-player'
import 'react-h5-audio-player/lib/styles.css'
import { BottomDrawerBackground, BottomDrawer } from './BottomDrawer'
import { Exiting } from './ExitAnimation'

const PlayerGlobalStyles = createGlobalStyle`
  .rhap_container {
    touch-action: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
  }
  .rhap_container * {
    user-select: none;
    -webkit-tap-highlight-color: transparent;
  }
  .rhap_time {
    min-width: 40px;
  }
  .rhap_container:focus {
    outline: none;
  }
  .rhap_container *:focus {
    outline: none;
  }
  .rhap_volume-controls {
    margin-right: 12px;
  }
`

const emptyArray = []

export function ListenPlayer({ cardIndex, onNext, onPrevious }) {
  return (
    <BottomDrawer>
      <Exiting>
        <BottomDrawerBackground
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <AudioPlayer
            key={cardIndex}
            autoPlay
            showSkipControls
            showJumpControls={false}
            src={trackURL(cardIndex)}
            customAdditionalControls={emptyArray}
            onEnded={onNext}
            onClickNext={onNext}
            onClickPrevious={onPrevious}
          />
        </BottomDrawerBackground>
      </Exiting>
      <PlayerGlobalStyles />
    </BottomDrawer>
  )
}
