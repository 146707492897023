import React from 'react'

export const NoBackground = () => (
  <style>{`
    body::after {
      opacity: 0;
    }
  `}
  </style>
)

