import React from 'react';
import styled from 'styled-components';
import { CardContainer, Card } from './Card'

const PlaylistShowcaseContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`

const PlaylistShowcaseCardContainer = styled.div`
  position: relative;
  width: ${props => props.theme.card.width * 0.5}px;
  height: ${props => props.theme.card.height * 0.5}px;
  margin: 4px;
`

const PlaylistShowcaseCard = styled(CardContainer)`
  left: 0;
  top: 0;
  transform-origin: 0 0;
`

export const PlaylistShowcase = ({ className, playlist }) => {
  if (!playlist || playlist.length <= 0) { return null }

  return (
    <PlaylistShowcaseContainer className={className}>
      {playlist.map((cardIndex, index) => (
        <PlaylistShowcaseCardContainer key={index}>
          <PlaylistShowcaseCard initial={{ scale: 0.5 }}>
            <Card cardIndex={cardIndex} />
          </PlaylistShowcaseCard>
        </PlaylistShowcaseCardContainer>
      ))}
    </PlaylistShowcaseContainer>
  )
}
