import styled from 'styled-components'

export const Input = styled.input`
  box-sizing: border-box;
  border: 1px black solid;
  padding: 8px;
  width: 100%;
  font-family: ${props => props.theme.fonts.body};
  font-size: 20px;
  border-radius: 8px;
  outline: none;
  transition: box-shadow linear 0.2s;

  &:focus {
    box-shadow: 0 0 1px 1px black;
  }
`
