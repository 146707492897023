import React from 'react'
import { Button } from './Button'
import { Exiting, useNavigateExitingTo } from './ExitAnimation'
import { ArticleWrapper } from './ArticleWrapper'
import styled from 'styled-components'

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;

  ${Button} {
    display: block;
    margin-bottom: 16px;
  }
`

export const ModePickPage = () => {
  const onGoDeal = useNavigateExitingTo('/deal')
  const onGoPick = useNavigateExitingTo('/pick')

  return (
    <Exiting>
      <ArticleWrapper
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <h1>Start Your Experiment</h1>
        <ButtonContainer>
          <Button onClick={onGoDeal}>
            Random Deal
          </Button>
          <Button onClick={onGoPick}>
            Choose Your Own Playlist
          </Button>
        </ButtonContainer>
      </ArticleWrapper>
    </Exiting>
  )
}

