import React, { useEffect } from 'react'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'

const Overlay = styled(motion.div)`
  position: fixed;
  z-index: 300;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
`

const ModalContainer = styled(motion.div)`
  position: fixed;
  z-index: 301;
  transform: translate(-50%, -50%);
  left: 50vw;
  top: 50vh;
  width: 600px;
  min-height: 200px;
  max-width: calc(100vw - 16px);
  max-height: calc(100vh - 16px);

  background-color: ${props => props.theme.colors.lightBackground};
  border-radius: 8px;
  ${props => props.theme.shadow};

  display: flex;
  flex-direction: column;
  align-items: stretch;
`

export const ModalTitle = styled.h1`
  margin-top: 16px;
  margin-bottom: 0px;
  margin-left: 44px;
  margin-right: 44px;
`

const ModalContentStyled = styled.div`
  overflow-y: auto;
`

const ModalContent = (props) => (
  <ModalContentStyled {...props} className={['allow-scroll', props.className]} />
)

export const ModalTextContent = styled(ModalContent)`
  padding-left: 16px;
  padding-right: 16px;
`

const ModalCloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  font-size: 36px;
  width: 44px;
  line-height: 44px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
  user-select: none;

  &:hover {
    opacity: 0.6;
  }
`

const stopPropagation = (evt) => {
  evt.stopPropagation()
}

const usePreventScroll = (enabled) => {
  useEffect(() => {
    if (!enabled) { return }
    const body = window.document.body
    const left = window.scrollX
    const top = window.scrollY
    body.style.position = 'fixed'
    body.style.left = `${-left}px`;
    body.style.top= `${-top}px`;
    return () => {
      body.style.position = null
      body.style.left = null
      body.style.top = null
      window.scrollTo(left, top)
    }
  }, [enabled])
}

export const Modal = ({ open, children, onClose }) => {
  usePreventScroll(open)

  return <>
    <AnimatePresence>
      {open && (
        <Overlay
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            duration: 0.3,
            easing: 'linear',
          }}
          onClick={onClose}
        />
      )}
    </AnimatePresence>
    <AnimatePresence>
      {open && (
        <ModalContainer
          initial={{
            scale: 0.8,
            opacity: 0,
            x: '-50%',
            y: '-50%',
          }}
          animate={{
            scale: 1,
            opacity: 1,
            x: '-50%',
            y: '-50%',
          }}
          transition={{
            duration: 0.3,
            easing: 'easeOut',
          }}
          exit={{
            scale: 0.8,
            opacity: 0,
            x: '-50%',
            y: '-50%',
          }}
          onClick={stopPropagation}
        >
          <ModalCloseButton onClick={onClose}>&times;</ModalCloseButton>
          {children}
        </ModalContainer>
      )}
    </AnimatePresence>
  </>
}
