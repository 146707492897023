import React, { useCallback } from 'react'
import { Redirect } from 'react-router-dom'
import { useSession, useSetSession, serializeSession } from '../session'
import { ListenCards } from './ListenCards'
import { ListenPlayer } from './ListenPlayer'
import { useNavigateExiting } from './ExitAnimation'
import { NoBackground } from './NoBackground'

const emptyObj = {}
const emptyArray = []

export const ListenPage = ({ shared }) => {
  const session = useSession() ?? emptyObj
  const setSession = useSetSession()

  const { playlist } = session
  const words = session.words ?? emptyArray
  const playlistCursor = session.cursor ?? 0
  const playlistMax = session.max ?? (((shared && playlist && playlist.length) || 1) - 1)

  const setPlaylistCursor = useCallback((updater) => {
    const cursor = updater(playlistCursor)
    const max = Math.max(playlistMax, cursor)
    setSession({ ...session, cursor, max })
  }, [playlistCursor, playlistMax, session, setSession])

  const navigateExiting = useNavigateExiting()

  const onNext = useCallback(() => {
    if (playlistCursor >= playlist.length - 1) {
      navigateExiting(history => {
        const newSession = {
          id: session.id, playlist, words, quote: session.quote, name: session.name,
        }
        const serializedSession = serializeSession(newSession)
        if (shared) {
          history.push('/shared/end?d=' + serializedSession)
        } else {
          history.push('/share?d=' + serializedSession)
        }
      })
      return
    }
    setPlaylistCursor(cursor => Math.min(cursor + 1, playlist.length - 1))
  }, [playlist, playlistCursor, setPlaylistCursor, words, navigateExiting, session.quote, session.name, shared, session.id])

  const onPrevious = useCallback(() => {
    setPlaylistCursor(cursor => Math.max(cursor - 1, 0))
  }, [setPlaylistCursor])

  const onChangeWord = useCallback((index, wordId) => {
    const newWords = [...words]
    while (index >= newWords.length) {
      newWords.push(null)
    }
    newWords[index] = wordId == null ? null : wordId
    const newSession = { ...session, words: newWords }
    setSession(newSession)
  }, [words, session, setSession])

  if (!Array.isArray(playlist) || playlist.length === 0) {
    return <Redirect to='/'/>
  }

  return <>
    <NoBackground />
    <ListenCards
      words={words}
      playlist={playlist}
      playlistCursor={playlistCursor}
      maxPlaylistCursor={playlistMax}
      onChangeWord={onChangeWord}
      shared={shared}
    />
    <ListenPlayer
      cardIndex={playlist[playlistCursor]}
      onNext={onNext}
      onPrevious={onPrevious}
    />
  </>
}
