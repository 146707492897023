import React, { memo } from 'react'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import { theme, desktopTheme } from '../constants/theme'
import { DealPage } from './DealPage'
import { PickPage } from './PickPage'
import { Preloader } from './Preloader'
import { AnimateSharedLayout } from 'framer-motion'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { WelcomePage } from './WelcomePage'
import { ListenPage } from './ListenPage'
import { ExitingProvider } from './ExitAnimation'
import { HeaderWrapper } from './Header'
import useResize from 'use-resize'
import { SharePage } from './SharePage'
import { SharedWelcomePage } from './SharedWelcomePage'
import { SharedEndPage } from './SharedEndPage'
import { PrivacyPolicyPage } from './PrivacyPolicyPage'
import { ModePickPage } from './ModePickPage'
import { StartPhysicalDeck } from './StartPhysicalDeck'
import backgroundImage from './images/background.jpg'

const GlobalStyles = createGlobalStyle`
  :root {
    height: 100%;
    touch-action: pan-x pan-y;
  }

  body {
    margin: 0;
    width: 100%;
    font-family: ${theme.fonts.body};
    color: ${theme.colors.text};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    touch-action: pan-x pan-y;
    font-size: 16px;
    position: relative;
  }

  body::after {
    content: "";
    background-image: url(${backgroundImage});
    background-size: 50%;
    background-position: 0px ${theme.headerHeight}px;
    background-repeat: repeat;
    opacity: 1;
    transition: opacity 0.5s linear;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }

  @media screen and (max-width: 600px) {
    body::after {
      background-size: 100%;
    }
  }

  @media screen and (min-width: 601px) and (max-width: 800px) {
    body::after {
      background-size: 75%;
    }
  }

  h1 {
    font-family: ${theme.fonts.stylish};
    text-transform: uppercase;
    text-align: center;
  }

  p {
    font-size: 20px;
  }
`

function Routes() {
  return (
    <Switch>
      <Route path='/' exact>
        <WelcomePage />
      </Route>
      <Route path='/deal' exact>
        <DealPage />
      </Route>
      <Route path='/pick' exact>
        <PickPage />
      </Route>
      <Route path='/listen' exact>
        <ListenPage />
      </Route>
      <Route path='/start' exact>
        <ModePickPage />
      </Route>
      <Route path='/share' exact>
        <SharePage />
      </Route>
      <Route path='/shared' exact>
        <SharedWelcomePage />
      </Route>
      <Route path='/shared/listen' exact>
        <ListenPage shared/>
      </Route>
      <Route path='/shared/end' exact>
        <SharedEndPage />
      </Route>
      <Route path='/privacy-policy' exact>
        <PrivacyPolicyPage />
      </Route>
      <Route path='/start-physical-deck' exact>
        <StartPhysicalDeck />
      </Route>
      <Route>
        <Redirect to='/' />
      </Route>
    </Switch>
  )
}

const MemoThemeProvider = memo(({ theme, children }) => (
  <ThemeProvider theme={theme}>
    {children}
  </ThemeProvider>
))

const AppThemeProvider = ({ children }) => {
  const { width, height } = useResize()
  return (
    <MemoThemeProvider theme={
      (width >= 600 && height >= 600)
        ? desktopTheme : theme
    }>
      {children}
    </MemoThemeProvider>
  )
}

export default function App() {
  return (
    <>
      <GlobalStyles />
      <Preloader />
      <Router>
        <AppThemeProvider>
          <AnimateSharedLayout>
            <ExitingProvider>
              <HeaderWrapper>
                <Routes />
              </HeaderWrapper>
            </ExitingProvider>
          </AnimateSharedLayout>
        </AppThemeProvider>
      </Router>
    </>
  )
}
