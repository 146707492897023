export default [
  {
    "composerFirstName": "Johannes",
    "composerLastName": "Brahms",
    "composerBirthYear": 1833,
    "composerDeathYear": 1897,
    "title": "String Quartet in C minor, Op. 51 No. 1",
    "year": 1873,
    "featuring": "",
    "movement": "I. Allegro",
    "duration": 675
  },
  {
    "composerFirstName": "Johannes",
    "composerLastName": "Brahms",
    "composerBirthYear": 1833,
    "composerDeathYear": 1897,
    "title": "String Quartet in C minor, Op. 51 No. 1",
    "year": 1873,
    "featuring": "",
    "movement": "II. Romanze. Poco adagio",
    "duration": 447
  },
  {
    "composerFirstName": "Johannes",
    "composerLastName": "Brahms",
    "composerBirthYear": 1833,
    "composerDeathYear": 1897,
    "title": "String Quartet in C minor, Op. 51 No. 1",
    "year": 1873,
    "featuring": "",
    "movement": "III. Allegretto molto moderato e comodo",
    "duration": 574
  },
  {
    "composerFirstName": "Johannes",
    "composerLastName": "Brahms",
    "composerBirthYear": 1833,
    "composerDeathYear": 1897,
    "title": "String Quartet in C minor, Op. 51 No. 1",
    "year": 1873,
    "featuring": "",
    "movement": "IV. Allegro",
    "duration": 384
  },
  {
    "composerFirstName": "Arnold",
    "composerLastName": "Schoenberg",
    "composerBirthYear": 1874,
    "composerDeathYear": 1951,
    "title": "String Quartet No. 3, Op. 30",
    "year": 1927,
    "featuring": "",
    "movement": "I. Moderato",
    "duration": 559
  },
  {
    "composerFirstName": "Arnold",
    "composerLastName": "Schoenberg",
    "composerBirthYear": 1874,
    "composerDeathYear": 1951,
    "title": "String Quartet No. 3, Op. 30",
    "year": 1927,
    "featuring": "",
    "movement": "II. Adagio",
    "duration": 501
  },
  {
    "composerFirstName": "Arnold",
    "composerLastName": "Schoenberg",
    "composerBirthYear": 1874,
    "composerDeathYear": 1951,
    "title": "String Quartet No. 3, Op. 30",
    "year": 1927,
    "featuring": "",
    "movement": "III. Intermezzo. Allegro Moderato",
    "duration": 430
  },
  {
    "composerFirstName": "Arnold",
    "composerLastName": "Schoenberg",
    "composerBirthYear": 1874,
    "composerDeathYear": 1951,
    "title": "String Quartet No. 3, Op. 30",
    "year": 1927,
    "featuring": "",
    "movement": "IV. Rondo. Molto Moderato",
    "duration": 399
  },
  {
    "composerFirstName": "Ruth",
    "composerLastName": "Crawford (Seeger)",
    "composerBirthYear": 1901,
    "composerDeathYear": 1953,
    "title": "String Quartet",
    "year": 1931,
    "featuring": "",
    "movement": "I. Rubato assai",
    "duration": 197
  },
  {
    "composerFirstName": "Ruth",
    "composerLastName": "Crawford (Seeger)",
    "composerBirthYear": 1901,
    "composerDeathYear": 1953,
    "title": "String Quartet",
    "year": 1931,
    "featuring": "",
    "movement": "II. Leggiero",
    "duration": 131
  },
  {
    "composerFirstName": "Ruth",
    "composerLastName": "Crawford (Seeger)",
    "composerBirthYear": 1901,
    "composerDeathYear": 1953,
    "title": "String Quartet",
    "year": 1931,
    "featuring": "",
    "movement": "III. Andante",
    "duration": 233
  },
  {
    "composerFirstName": "Ruth",
    "composerLastName": "Crawford (Seeger)",
    "composerBirthYear": 1901,
    "composerDeathYear": 1953,
    "title": "String Quartet",
    "year": 1931,
    "featuring": "",
    "movement": "IV. Allegro possibile",
    "duration": 122
  },
  {
    "composerFirstName": "Sam",
    "composerLastName": "Pluta",
    "composerBirthYear": 1979,
    "composerDeathYear": "",
    "title": "binary/momentary logics: flow state/joy state",
    "year": 2016,
    "featuring": "",
    "movement": "",
    "duration": 529
  },
  {
    "composerFirstName": "Anthony",
    "composerLastName": "Cheung",
    "composerBirthYear": 1982,
    "composerDeathYear": "",
    "title": "The Real Book of Fake Tunes",
    "year": 2015,
    "featuring": "Claire Chase, flutes",
    "movement": "Mvt I",
    "duration": 162
  },
  {
    "composerFirstName": "Anthony",
    "composerLastName": "Cheung",
    "composerBirthYear": 1982,
    "composerDeathYear": "",
    "title": "The Real Book of Fake Tunes",
    "year": 2015,
    "featuring": "Claire Chase, flutes",
    "movement": "Mvt II",
    "duration": 150
  },
  {
    "composerFirstName": "Anthony",
    "composerLastName": "Cheung",
    "composerBirthYear": 1982,
    "composerDeathYear": "",
    "title": "The Real Book of Fake Tunes",
    "year": 2015,
    "featuring": "Claire Chase, flutes",
    "movement": "Mvt III",
    "duration": 204
  },
  {
    "composerFirstName": "Anthony",
    "composerLastName": "Cheung",
    "composerBirthYear": 1982,
    "composerDeathYear": "",
    "title": "The Real Book of Fake Tunes",
    "year": 2015,
    "featuring": "Claire Chase, flutes",
    "movement": "Mvt IV",
    "duration": 207
  },
  {
    "composerFirstName": "Anthony",
    "composerLastName": "Cheung",
    "composerBirthYear": 1982,
    "composerDeathYear": "",
    "title": "The Real Book of Fake Tunes",
    "year": 2015,
    "featuring": "Claire Chase, flutes",
    "movement": "Mvt V",
    "duration": 249
  },
  {
    "composerFirstName": "Charmaine",
    "composerLastName": "Lee",
    "composerBirthYear": 1991,
    "composerDeathYear": "",
    "title": "Spinals",
    "year": 2018,
    "featuring": "Charmaine Lee, vocals and electronics",
    "movement": "",
    "duration": 376
  },
  {
    "composerFirstName": "George",
    "composerLastName": "Lewis",
    "composerBirthYear": 1952,
    "composerDeathYear": "",
    "title": "String Quartet 1.5: Experiments in Living",
    "year": 2016,
    "featuring": "",
    "movement": "",
    "duration": 996
  }
]
