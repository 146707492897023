import React from 'react'
import styled from 'styled-components'

export const BuyCTAText = () => (
  <>
    If you like <strong>Experiments in Living</strong>, consider supporting us by{' '}
    <a href='https://spektralquartet.com/experiments-in-living' target='_blank' rel='noopener noreferrer'>
      buying the album
    </a>.
  </>
)

const SectionTitle = styled.h3`
  text-align: center;
`
export const BuyCTA = () => (
  <SectionTitle>
    <BuyCTAText />
  </SectionTitle>
)
